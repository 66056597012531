import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import {
  Box,
  Button,
  Tooltip,
  FormControl,
  Select,
  MenuItem,
  InputLabel
} from '@mui/material';
import {
  Add,
  WarningAmberOutlined,
  PublishedWithChanges
} from '@mui/icons-material';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import ConfirmationDialog from '../Dialog/ConfirmationDialog';
import CustomButton from '../Common/CustomButton';
import TestCaseSet from './TestCaseSet';
import LoadingOverlay from '../Common/LoadingOverlay';
import { useSnackbar } from "../../contexts/CustomSnackbarContext";
import { updateTestCaseSetList } from "../../redux-store/testCaseSetReducers/testCaseSetSlice";
import { fetchTestCaseSetList, deleteTestCaseSet } from '../../redux-store/testCaseSetReducers/testCaseSetActions';
import { updateTestScenario } from '../../redux-store/testScenarioReducers/testScenarioActions';
import { getAllVariableSets } from '../../redux-store/variableSetReducers/variableSetActions';
import TestScenarioProcessManager from "./TestScenariosValidation/TestScenarioProcessManager";
import { Alert } from '@mui/material';

const TestCaseSetList = function({ testScenarioId, getNewTestCase, onDelete, projectId }) {
  const dispatch = useDispatch();
  const { openSnackbar } = useSnackbar();
  const [expandedId, setExpandedId] = useState("");
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteTestCaseSetDetail, setDeleteTestCaseSetDetail] = useState("");
  const testCaseSetList = useSelector(state => (state.testCaseSet.testCaseSetList));
  const isLoading = useSelector(state => state.testCaseSet.isLoading);
  const [showProcessManager, setShowProcessManager] = useState(false);
  const [showValidateConfirmation, setShowValidateConfirmation] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [environments, setEnvironments] = useState([]);
  const [selectedEnvironment, setSelectedEnvironment] = useState('');
  const selectedTestScenarioDetail = useSelector(
    (state) => state.testScenario.selectedTestScenarioDetail
  );

  useEffect(() => {
    getAllTestCases();
    fetchEnvironments();
  }, [projectId]);

  useEffect(() => {
    if (selectedTestScenarioDetail?.user_input_needed) {
      setShowAlert(true);
    }
  }, [selectedTestScenarioDetail?.user_input_needed]);

  const fetchEnvironments = async () => {
    try {
      if (projectId) {
        const result = await dispatch(getAllVariableSets({
          projectId: projectId,
          isEnvironment: true
        })).unwrap();
        console.log('Result:', result);
        if (result?.variable_sets)
        {
          console.log('Environments:', result.variable_sets);
          setEnvironments(result.variable_sets);
          // Set default environment if available
          if (result.variable_sets.length > 0) {
            const defaultEnv = result.variable_sets.find(env => env.title === 'default') || result.variable_sets[0];
            setSelectedEnvironment(defaultEnv);
          }
        }
      }
    } catch (error) {
      console.error('Error fetching environments:', error);
      openSnackbar({
        message: "Failed to fetch environments. Please try again.",
        severity: "error",
      });
    }
  };

  const handleEnvironmentChange = (event) => {
    setSelectedEnvironment(event.target.value);
  };

  const getAllTestCases = async () => {
    const testCaseSetList = await dispatch(fetchTestCaseSetList({testScenarioId: testScenarioId}));
    if (testCaseSetList?.payload?.length === 0) {
      await dispatch(updateTestCaseSetList({testCaseSetList: [getNewTestCase()]}));
    }
  };

  const onAddNewTestCase = async () => {
    await dispatch(updateTestCaseSetList({testCaseSetList: [...testCaseSetList, getNewTestCase()]}));
  };

  const updateOrder = async (testCaseSetListUpdated) => {
    await dispatch(updateTestScenario({
      testScenarioId: testScenarioId,
      data: {
        test_case_sets_order: testCaseSetListUpdated.filter(item => !item.isNew).map(item => item.id)
      }
    }));
  };

  const updateList = async (testCaseSetListUpdated, orderChanged=false) => {
    await dispatch(updateTestCaseSetList({testCaseSetList: [...testCaseSetListUpdated]}));
    if (orderChanged) {
      updateOrder(testCaseSetListUpdated);
    }
  };

  const onExpand = (id) => {
    if (id !== expandedId) {
      setExpandedId(id);
    } else {
      setExpandedId("");
    }
  };

  const onClickDelete = (testCaseSet) => {
    setDeleteTestCaseSetDetail(testCaseSet);
    onToggleDeleteDialog();
  };

  const onDeleteConfirm = async () => {
    const testCaseSetIndex = testCaseSetList.findIndex(testCaseSet => testCaseSet.id === deleteTestCaseSetDetail.id);
    const testCaseSetListUpdated = [
      ...testCaseSetList.slice(0, testCaseSetIndex),
      ...testCaseSetList.slice(testCaseSetIndex + 1, testCaseSetList.length)
    ];

    if (!deleteTestCaseSetDetail.isNew) {
      await dispatch(deleteTestCaseSet({testCaseSetId: deleteTestCaseSetDetail.id}));
    }
    await dispatch(updateTestCaseSetList({testCaseSetList: [...testCaseSetListUpdated]}));
    onToggleDeleteDialog();
    updateOrder(testCaseSetListUpdated);
    openSnackbar({message: "Test case deleted successfully.", severity: "success"});
    if (testCaseSetListUpdated.length === 0) {
      getAllTestCases();
    }
  };

  const onToggleDeleteDialog = () => {
    setDeleteDialogOpen(prevState => !prevState);
    if (deleteTestCaseSetDetail?.id) {
      setDeleteTestCaseSetDetail({});
    }
  };

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }
    const draggedTestCase = testCaseSetList[result?.source?.index];
    const sourceIndex = result?.source?.index;
    const destinationIndex = result?.destination?.index;

    if (sourceIndex > destinationIndex) {
      const firstHalf = testCaseSetList.slice(0, result?.destination?.index);
      const secondHalf = testCaseSetList.slice(result?.destination?.index).filter(item => item.id !== result?.draggableId);
      updateList([...firstHalf, draggedTestCase, ...secondHalf], true);
    } else {
      const firstHalf = testCaseSetList.slice(0, result?.destination?.index + 1).filter(item => item.id !== result?.draggableId);
      const secondHalf = testCaseSetList.slice(result?.destination?.index + 1);
      updateList([...firstHalf, draggedTestCase, ...secondHalf], true);
    }
  };

  const renderConfirmDialog = () => {
    return (
      <ConfirmationDialog
        open={deleteDialogOpen}
        onClose={onToggleDeleteDialog}
        onConfirm={onDeleteConfirm}
        title="Confirm Delete"
        content="Are you sure you want to delete?"
      />
    );
  };

  const renderValidateConfirmDialog = () => {
    return (
      <ConfirmationDialog
        open={showValidateConfirmation}
        onClose={() => setShowValidateConfirmation(false)}
        onConfirm={() => {
          setShowValidateConfirmation(false);
          setShowProcessManager(true);
        }}
        title="Confirm Action"
        content="This will delete all the request body and headers and will setup everything again with AI."
        actionText="Continue"
        cancelText="Cancel"
        actionTextColor="primary"
        cancelTextColor="error"
      />
    );
  };

  const renderDragNDrop = () => {
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {testCaseSetList.map((testCaseSet, index) => (
                <Draggable
                  key={testCaseSet.id}
                  draggableId={testCaseSet.id}
                  index={index}
                  isDragDisabled={expandedId === testCaseSet.id}
                >
                  {(provided, snapshot) => (
                    <div>
                      <div
                        ref={provided.innerRef}
                        {...provided.dragHandleProps}
                        {...provided.draggableProps}
                      >
                        <Box sx={{width: "100%", marginBottom: "16px"}} key={testCaseSet.id}>
                          <TestCaseSet
                            key={testCaseSet.id}
                            detail={testCaseSet}
                            isExpanded={expandedId === testCaseSet.id}
                            updateList={updateList}
                            onExpand={onExpand}
                            onClickDelete={() => onClickDelete(testCaseSet)}
                            selectedEnvironment={selectedEnvironment}
                          />
                        </Box>
                      </div>
                      {provided.placeholder}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  };

  const onValidateBtnClick = () => {
    if (selectedTestScenarioDetail?.test_scenario_process_id) {
      setShowProcessManager(true);
    } else {
      setShowValidateConfirmation(true);
    }
  };

  const handleCloseProcessManager = () => {
    setShowProcessManager(false);
  };

  const onCompleteProcesssManager = (status) => {
    if (status === true) {
      setShowProcessManager(false);
      getAllTestCases();
    }
  };

  const handleMarkAsActive = async () => {
    try {
      await dispatch(updateTestScenario({
        testScenarioId: testScenarioId,
        data: {
          status: 'active'
        }
      }));
      openSnackbar({
        message: "Test scenario successfully marked as active.",
        severity: "success",
      });
    } catch (error) {
      console.error('Error updating test scenario status:', error);
      openSnackbar({
        message: "Failed to mark test scenario as active. Please try again.",
        severity: "error",
      });
    }
  };

  let content = null;

  if (isLoading && testCaseSetList?.length === 0) {
    content = (
      <Box sx={{width: "100%", textAlign: "center", padding: "50px 32px"}}>
        <h3>Loading test cases...</h3>
      </Box>
    );
  }

  if (testCaseSetList?.length > 0) {
    content = (
      <div style={{paddingTop: "16px", paddingBottom: "150px"}}>
        {renderDragNDrop()}
        <CustomButton startIcon={<Add />} onClick={onAddNewTestCase}>
          Add New Test Case
        </CustomButton>
      </div>
    );
  }

  return (
    <Box sx={{ px: 2 }}>
      {showAlert && selectedTestScenarioDetail?.user_input_needed && (
        <Alert
          severity="warning"
          sx={{ mb: 2 }}
          onClose={() => setShowAlert(false)}
        >
          Additional Information Needed: Request data needs to be configured for this test scenario. Please click the 'Request data' button to proceed.
        </Alert>
      )}

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <h5 style={{ color: 'Black', fontWeight: '500', margin: 0 }}>Test Cases</h5>
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          {selectedTestScenarioDetail?.status === 'draft' && (
            <Button
              variant="outlined"
              color="primary"
              onClick={handleMarkAsActive}
              sx={{
                textTransform: 'none',
                fontSize: '12px',
                height: '36px',
                borderRadius: '4px',
              }}
              startIcon={<PublishedWithChanges fontSize="small" />}
            >
              Mark as Active
            </Button>
          )}

          {selectedTestScenarioDetail?.user_input_needed ? (
            <Tooltip
              title="Click here to configure the request data for this test scenario"
              placement="top"
              arrow
            >
              <Button
                variant="contained"
                color="primary"
                onClick={onValidateBtnClick}
                sx={{
                  textTransform: 'none',
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: '12px',
                  height: '36px',
                }}
                startIcon={<WarningAmberOutlined sx={{ color: '#FFF', fontSize: "small" }}/>}
              >
                Request data
              </Button>
            </Tooltip>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={onValidateBtnClick}
              sx={{
                textTransform: 'none',
                display: 'flex',
                alignItems: 'center',
                fontSize: '12px',
                height: '36px',
              }}
              startIcon={<AutoAwesomeIcon fontSize="small" sx={{marginRight: '0px'}}/>}
            >
              Create request data
            </Button>
          )}
        </Box>
      </Box>

      <Box sx={{ mb: 1 }}>
        <FormControl size="small">
          <InputLabel id="environment-select-label">Environment</InputLabel>
          <Select
            labelId="environment-select-label"
            id="environment-select"
            value={selectedEnvironment}
            label="Environment"
            onChange={handleEnvironmentChange}
            sx={{ minWidth: 200 }}
          >
                            {environments.map((env) => (
              <MenuItem key={env.id} value={env}>
                {env.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      {content}
      {deleteDialogOpen && renderConfirmDialog()}
      {showValidateConfirmation && renderValidateConfirmDialog()}
      <LoadingOverlay isLoading={isLoading} />
      {showProcessManager && (
        <TestScenarioProcessManager
          testScenarioId={testScenarioId}
          onClose={handleCloseProcessManager}
          onFinishProcess={onCompleteProcesssManager}
          selectedEnvironment={selectedEnvironment}
        />
      )}
    </Box>
  );
}

export default TestCaseSetList;