import {
  Box,
  Grid,
  Typography,
  Stack,
  Pagination,
  Toolbar,
  Tooltip
} from '@mui/material';
import { Info } from '@mui/icons-material';
import { useState, useEffect, useRef } from 'react';

import LoadingOverlay from '../components/Common/LoadingOverlay';
import SideDrawer from '../components/Common/SideDrawer';

const MainLayout = ({
  children,
  isLoading = false,
  headerText = '',
  headerContent = null, 
  subtitleText = '',
  subtitleContent = null,
  infoText = null,
  totalPages = 0,
  pageNumber = 1,
  onPageChange = () => {},
  rightSideContent = null,
  renderDrawerItem = () => {return null;},
  drawerItems = [],
  renderHeader,
  enableSideDrawer = false,
  sxSideDrawer={},
  selectedItemContent=null,
  sxLeftSide={},
  contentStyle= {}
}) => {
  const inputRef = useRef(null);
  const [localHeaderText, setLocalHeaderText] = useState(headerText);
  const [isHeaderEditing, setIsHeaderEditing] = useState(false);
  const [selectedItem, setSelectedItem] = useState('');

  useEffect(() => {
    if (headerText !== localHeaderText) setLocalHeaderText(headerText);
  }, [headerText]);

  useEffect(() => {
    if (!isHeaderEditing && localHeaderText !== headerText)
      setLocalHeaderText(headerText);
  }, [isHeaderEditing]);

  const onDrawerClick = (selectedItem) => {
    setSelectedItem(prevState => selectedItem);
    renderDrawerItem({selectedItem, onDrawerClose: onDrawerClose});
  };

  const onDrawerClose = () => {
    setSelectedItem('');
  };

  return (
    <>
      {<Box className="toolbar-container" sx={{paddingBottom: "16px", maxHeight: "136px", minHeight: "100px", position: "relative", zIndex: 10, overflow: "hidden"}}>
          <Toolbar variant="dense" className="tool-bar-style" sx={{zIndex: 2, height: "100%"}}>
            <Grid container sx={{ padding: '8px 20px' }} alignItems="center">
              <Grid item className="leftSide" sx={{maxWidth: "40%", ...sxLeftSide}}>
                {
                  renderHeader ? renderHeader() : (
                    <>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <h4
                          className="truncate-1-lines"
                          style={{
                            margin: '0px',
                            color: 'black',
                            fontSize: '24px',
                            cursor: 'default',
                          }}
                        >
                          {localHeaderText}
                        </h4>
                        {headerContent}
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <Typography variant="body1" color="gray" className="truncate-1-lines">
                            {subtitleText}
                          </Typography>
                          {subtitleContent}
                      </Box>
                      { 
                        infoText ? <Tooltip title={infoText} arrow placement="right">
                        <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'help' }}>
                          <Info fontSize="small" color="action" />
                        </Box>
                      </Tooltip>  : null
                      }
                      </Box>
                    </>
                  )
                }
              </Grid>
              {
                rightSideContent && (
                  <Grid className="margin-set">
                    <Grid
                      item
                      xs={12}
                      sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
                    >
                      {rightSideContent}
                    </Grid>
                  </Grid>
                )
              }
            </Grid>
          </Toolbar>
        </Box>
      }
      <Box sx={{ p: "24px", paddingTop: "32px", maxHeight: "calc(100vh - 100px)", width: enableSideDrawer ? "calc(100% - 86px)" : "100%", ...contentStyle }}>
        {children}
        <LoadingOverlay isLoading={isLoading} />
        {(totalPages > 1 && !isLoading) && (
          <Stack spacing={2} sx={{ margin: '8px', alignItems: 'center' }}>
            <Pagination
              count={totalPages}
              page={pageNumber}
              onChange={onPageChange}
            />
          </Stack>
        )}
      </Box>
      {
        enableSideDrawer && (
          <SideDrawer
            drawerItems={drawerItems}
            onChange={onDrawerClick}
            selectedItem={selectedItem}
            onClose={onDrawerClose}
            disabled={selectedItem.disabled}
            sx={{sxSideDrawer}}
          >
            {selectedItemContent}
          </SideDrawer>
        )
      }
    </>
  );
};

export default MainLayout;