import React, { useEffect, useState } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { apiAgent } from 'config/axiosConfig';
import MainLayout from 'layouts/MainLayout';

const TransactionHistory = ({ projectId }) => {
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const ITEMS_PER_PAGE = 25;

  useEffect(() => {
    const fetchTransactions = async () => {
      if (!projectId) return;
      
      setIsLoading(true);
      try {
        const response = await apiAgent({
          method: 'get',
          url: '/api/v1/ai_transactions/',
          params: {
            project_id: projectId,
            page: page,
            per_page: ITEMS_PER_PAGE
          }
        });
        setTransactions(response.data?.ai_transactions || []);
        // Assuming the API returns total_pages in the response
        setTotalPages(response.data?.total_pages || 1);
      } catch (error) {
        console.error('Error fetching transactions:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTransactions();
  }, [projectId, page]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const headerStyle = {
    fontWeight: 700,
    fontSize: '14px',
    backgroundColor: '#f5f5f5',
    padding: '16px'
  };

  const cellStyle = {
    fontSize: '13px',
    padding: '16px'
  };

  const formatDate = (timestamp) => {
    return new Date(timestamp * 1000).toLocaleDateString();
  };

  const renderTransactionTable = () => (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={headerStyle}>Date</TableCell>
            <TableCell style={headerStyle}>Task Name</TableCell>
            <TableCell style={headerStyle}>Credits Used</TableCell>
            <TableCell style={headerStyle}>User</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {transactions.map((transaction) => (
            <TableRow key={transaction.id} hover>
              <TableCell style={cellStyle}>
                {formatDate(transaction.created_at)}
              </TableCell>
              <TableCell style={cellStyle}>{transaction.task_name}</TableCell>
              <TableCell style={cellStyle}>{transaction.credits_used}</TableCell>
              <TableCell style={cellStyle}>{transaction.user.name}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <MainLayout
      headerText="Transaction History"
      subtitleText="View all your AI credit transactions"
      infoText="This shows the history of AI credits used across different tasks"
      isLoading={isLoading}
      totalPages={totalPages}
      pageNumber={page}
      onPageChange={handlePageChange}
    >
      <Box sx={{ p: 3 }}>
        {transactions.length === 0 && !isLoading ? (
          <Box sx={{ textAlign: 'center', py: 3 }}>
            <span style={{ color: '#666', fontSize: '15px' }}>No transactions found</span>
          </Box>
        ) : (
          renderTransactionTable()
        )}
      </Box>
    </MainLayout>
  );
};

export default TransactionHistory;