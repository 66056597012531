import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Typography,
  TextField,
  Tooltip
} from "@mui/material";
import MonacoEditor from "react-monaco-editor";
import { useDispatch } from "react-redux";
import {
  ExpandLess,
  DeleteOutline,
  Create,
  CheckOutlined,
  Close,
  InfoOutlined
} from "@mui/icons-material";
import ConfirmationDialog from "../Dialog/ConfirmationDialog";

import coreUtils from "../../utils/coreUtils";
import { useSnackbar } from "../../contexts/CustomSnackbarContext";

import "../../styles/components/TestScenarios/TestRulesSelected.scss";

const TestRulesSelected = ({
  testRules,
  testCase,
  isExpanded,
  setAlert,
  editTestRule,
  removeTestRule,
  onUpdateRule,
  isSaving = false,
}) => {
  const dispatch = useDispatch();
  const { openSnackbar } = useSnackbar();
  const [expandedRuleId, setExpandedRuleId] = useState("");
  const [deleteRuleId, setDeleteRuleId] = useState("");
  const [editId, setEditId] = useState(false);
  const summaryRef = useRef(null);
  const [ruleExpression, setRuleExpression] = useState("");
  const [title, setTitle] = useState("");

  const onDeleteTestRule = async () => {
    const indexOfRule = testRules.findIndex((rule) => rule.id === deleteRuleId);
    const deleteRule = testRules[indexOfRule];
    if (deleteRule && deleteRule?.suggested) {
      const editedRule = { ...deleteRule, checked: false };
      editTestRule(editedRule);
    } else {
      removeTestRule(deleteRuleId);
    }
    setDeleteRuleId("");
    openSnackbar({message: "Test rule deleted successfully.", severity: "success"});
  };

  const cancelEdit = (event, ruleId) => {
    event.stopPropagation();
    setEditId("");
    // handleRuleExpand(ruleId);
  };

  const handleEdit = (event, rule) => {
    event.stopPropagation();
    if (expandedRuleId !== rule.id) handleRuleExpand(rule.id);
    setEditId(rule.id);
    setTitle(rule.title);
    setRuleExpression(rule.expression);
  };

  const handleSave = async (event, ruleId) => {
    event.stopPropagation();
    // call update test rule
    if (!coreUtils.isStringInvalidOrBlank(title) && !coreUtils.isStringInvalidOrBlank(ruleExpression)) {
      const data = {
        id: ruleId,
        title: title,
        expression: ruleExpression,
      };
      await onUpdateRule(data);
      setEditId("");
      setTitle("");
      setRuleExpression("");
    } else {
      openSnackbar({message: "Title & Expression are required!", severity: "warning"});
    }
    // handleRuleExpand(ruleId);
  };

  const handleTextFieldclick = (event) => {
    event.stopPropagation();
  };

  const handleExpressionChange = (value, isEdit) => {
    setRuleExpression(value);
  };

  const handleTitleChange = (event, ruleId) => {
    setTitle(event.target.value);
  };

  const renderDeleteModal = () => {
    return (
      <ConfirmationDialog
        open={deleteRuleId}
        onClose={() => {
          setDeleteRuleId("");
        }}
        onConfirm={onDeleteTestRule}
        title="Confirm Test Rule Delete"
        content="Are you sure you want to delete this test rule?"
      />
    );
  };

  const handleRuleExpand = (ruleId) => {
    if (expandedRuleId === ruleId) {
      setExpandedRuleId("");
    } else {
      setExpandedRuleId(ruleId);
    }
  };

  const getMonacoEditor = (isEdit, expression) => {
    const options = {
      automaticLayout: true,
      wordWrap: "on",
      minimap: { enabled: false },
      lineNumbers: "off",
      tabSize: 4,
      overviewRulerLanes: 0
    };
    if (isEdit) options.readOnly = false;
    else options.readOnly = true;
    return (
      <MonacoEditor
        className="jsEditor"
        language="javascript"
        theme="vs-dark"
        value={expression}
        height="150"
        onChange={(value) => {
          handleExpressionChange(value, isEdit);
        }}
        style={{borderBottom: "1px solid #e4e4e4"}}
        options={options}
      />
    )
  }

  const renderSingleRule = (rule) => {
    const isEdit = rule.id === editId;
    return (
      <Accordion
        key={rule.id}
        expanded={rule.id === expandedRuleId}
        sx={{ my: 1 }}
        // disabled={isSaving}
      >
        <AccordionSummary
          expandIcon={<ExpandLess />}
          aria-controls="suggested-testrule-content"
          id={rule.id}
          onClick={(event) => {
            handleRuleExpand(rule.id);
          }}
          sx={{ "&.Mui-focusVisible": { backgroundColor: "transparent" } }}
          className="ruleSummary"
        >
          {!isEdit && (
            <Typography
              sx={{ width: "24px" }}
              className="truncate-2-lines title"
            >
              {rule.title}
            </Typography>
          )}
          {isEdit && (
            <TextField
              size="small"
              className="truncate-1-lines title"
              value={title}
              onClick={handleTextFieldclick}
              onChange={(event) => {
                handleTitleChange(event, rule.id);
              }}
            />
          )}

          {isEdit && (
            <IconButton
              onClick={(e) => {
                handleSave(e, rule.id);
              }}
              disabled={isSaving}
            >
              <CheckOutlined />
            </IconButton>
          )}
          {isEdit && (
            <IconButton
              onClick={(e) => {
                cancelEdit(e, rule.id);
              }}
              disabled={isSaving}
            >
              <Close />
            </IconButton>
          )}
          {!isEdit && (
            <IconButton
              onClick={(e) => {
                handleEdit(e, rule);
              }}
            >
              <Create />
            </IconButton>
          )}
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              setDeleteRuleId(rule.id);
            }}
          >
            <DeleteOutline />
          </IconButton>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{border: "1px solid #e4e4e4", borderRadius: "4px", p: 1}}>
            <Typography sx={{mb: "4px", fontWeight: 550, position: "relative"}}>
              function () {"{"}
              <Tooltip 
                title="Function should always return a boolean value."
                placement="left-end"
                sx={{fontFamily: "Inter, sans-serif", fontWeight: "550"}}
              >
                <InfoOutlined size="small" sx={{position: "absolute", right: 0, cursor: "pointer"}} />
              </Tooltip>
            </Typography>
            {getMonacoEditor(isEdit, isEdit ? ruleExpression : rule.expression)}
            <Typography sx={{fontWeight: 550}}>{"}"}</Typography>
          </Box>
        </AccordionDetails>
      </Accordion>
    );
  };

  const renderTestRules = () => {
    if (testRules) {
      return testRules.map((rule) => renderSingleRule(rule));
    }
    return null;
  };

  return (
    <Box className="testRulesSelected">
      {renderTestRules()}
      {deleteRuleId && renderDeleteModal()}
    </Box>
  );
};

export default TestRulesSelected;
