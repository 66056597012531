import React, { useState, useEffect } from 'react';
import { useDispatch, connect } from "react-redux";
import { useHistory, useLocation, Switch, Route } from "react-router-dom";
import './styles/App.scss';
import RouteRenderer from './routes/routeRenderer';
import routes from './routes';
import { CircularProgress } from '@mui/material';
import { SnackbarProvider } from './contexts/CustomSnackbarContext';
import CustomSnackbar from './components/Common/CustomSnackbar';
import { GuestUserProvider } from './contexts/GuestUserContext';
import { Helmet } from 'react-helmet';
import { isLoggedIn } from 'redux-store/dashboardActions';
import GlobalErrorSnackbar from 'components/Common/GlobalErrorSnackbar';

function App({user}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  
  const queryParams = new URLSearchParams(location.search);
  const forkCollectionId = queryParams.get('fork-collection');
  if (forkCollectionId) {
    localStorage.setItem("forkCollectionId", forkCollectionId);
  }

  // Array of public routes that don't require authentication
  const publicRoutes = [
    '/login',
    '/',
    '/confirm-email',
    '/forgot-password',
    '/reset-password',
    '/auto-login'  
  ];

  const validateUser = async () => {
    setIsLoading(true);
    
    const currentPath = location.pathname;
    
    if (currentPath === '/auto-login') {
      setIsLoading(false);
      return;
    }
    const response = await dispatch(isLoggedIn());
    const validated = response.payload;

    if (validated) {
      if (publicRoutes.includes(currentPath)) {
        window.location = '/collections';
      } else {
        setIsLoading(false);
      }
    } else {
      if (!publicRoutes.some(route => 
        currentPath === route || 
        (route === '/reset-password' && currentPath.startsWith('/reset-password'))
      )) {
        localStorage.clear();
        window.location = '/login';
      } else {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    validateUser();
  }, []);

  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <SnackbarProvider>
      <GuestUserProvider>
        <div className="textTitle">
          <Switch>
            {routes.map((route, index) => (
              <RouteRenderer {...route} key={index} />
            ))}
            <Route
              path="/terms_condition"
              exact
              render={() => {
                window.location.href = 'https://www.qodex.ai/termsandconditions';
                return null;
              }}
            />
          </Switch>
          <Helmet>
            {process.env.REACT_APP_ENVIRONMENT !== "production" && (
              <meta name="robots" content="noindex"></meta>
            )}
          </Helmet>
        </div>
      </GuestUserProvider>
      <CustomSnackbar />
      <GlobalErrorSnackbar/>
    </SnackbarProvider>
  );
}

function mapStateToProps(state, ownProps){
  const user = state.user.current_user || {};
  return {
    ...ownProps,
    user: user
  }
}

export default connect(mapStateToProps)(App);