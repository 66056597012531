import React, { useState, useMemo, useEffect } from 'react';
import { Box, Typography, Link } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import ApiComponent from "./ApiComponent";
import ResponseComponent from "../Collections/ResponseComponent";
import OverviewComponent from '../Collections/OverviewComponent.jsx';
import CollectedSamplesTable from '../Samples/CollectedSamplesTable';
import ApiOptions from './ApiOptions';
import { AppProvider } from '../../AppContext';
import { useDispatch, connect } from "react-redux";
import { getEntity } from "../../redux-store/currentUserActions";

const ApiScreen = ({ state, api, onEntitySlugUpdate, selectedSidebarState, entities }) => {
  const dispatch = useDispatch();
  const [apiOverview, setApiOverview] = useState(false);
  const [sendClicked, setSendClicked] = useState(false);
  const [selectedTab, setSelectedTab] = useState('option2');
  const [isMinimized, setIsMinimized] = useState(false);
  const [selectedEnvironment, setSelectedEnvironment] = useState('');

  const entity = useMemo(() => {
    return entities?.[selectedSidebarState?.id] || null;
  }, [selectedSidebarState?.id, entities]);

  const requestBoxStyle = {
    ...selectedTab === "option2" && { height: !isMinimized ? '45%' : '22%', overflow: 'hidden', transition: 'height 0.5s ease-in-out' }
  };

  const responseBoxStyle = {
    flexGrow: 1,
    height: !isMinimized ? '55%' : '78%',
    transition: 'height 0.5s ease-in-out'
  };

  useEffect(() => {
    const fetchEntity = async () => {
      try {
        await dispatch(getEntity({ id: selectedSidebarState?.id }));
      } catch (error) {
        console.error('Error fetching entity:', error);
      }
    };

    if (selectedSidebarState?.id && 
      !entity && 
      selectedSidebarState?.type === 'entity') {
    fetchEntity();
  }
  }, [selectedSidebarState, entity, dispatch]);

  const toggleHeight = () => {
    setIsMinimized(!isMinimized);
  };

  useEffect(() =>
  {
    console.log("selected environment", selectedEnvironment);
  }, [selectedEnvironment]);

  const titleParts = [];
  if (state?.collection?.name) titleParts.push(state.collection.name);
  if (state?.folder) titleParts.push(state.folder.name);
  if (state?.collectionApi) titleParts.push(state.collectionApi.name);
  if (state?.folderApi) titleParts.push(state.folderApi.name);

  const renderTabContent = () => {
    if(apiOverview){
      return <OverviewComponent resource={entity} />
    } else if (selectedTab === 'option2'){
      return <ApiComponent state={state} api={api} entity={entity} onEntitySlugUpdate={onEntitySlugUpdate} selectedEnvironment={selectedEnvironment} />
    } else if (selectedTab === 'option3'){
      return <CollectedSamplesTable state={ state } api={ api } />
    }
  };

  const handleSelectedOption = (option) => {
    setSelectedTab(option);
    setApiOverview(option === 'option1');
  }

  return (
    <AppProvider value={{ sendClicked, setSendClicked }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>

        {/* Request */}
        <Box sx={ requestBoxStyle }>
          <ApiOptions selectedIcon={handleSelectedOption} api={api} state={state} onSelectEnvironment={setSelectedEnvironment} />
          {/* <Box sx={{ flexGrow: 1, overflowY: 'auto', marginTop: '10px', marginBottom: '10px')' }}> */}
          <Box sx={{ height: '100%', flexGrow: 1, overflowY: 'auto', '&::-webkit-scrollbar': { display: 'none' }, 'msOverflowStyle': 'none', 'scrollbarWidth': 'none', ...(selectedTab === 'option1' ? { marginLeft: '15px', marginRight: '15px' } : {}) }}>
            {renderTabContent()}
          </Box>
        </Box>

        {/* Response */}
        <Box sx={ responseBoxStyle }>
          { selectedTab === 'option2' && <ResponseComponent state={state} entity={entity} toggleHeight={toggleHeight} /> }
        </Box>
      </Box>
    </AppProvider>
  );
}

function mapStateToProps(state, ownProps){
  return {
    ...ownProps,
    selectedSidebarState: state.user.selectedSidebarState,
    entities: state.user.entities
  }
}
export default connect(mapStateToProps)(ApiScreen);