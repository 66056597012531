import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import useTestScenarioPolling from './useTestScenarioPolling';
import ChatWindow from './ChatWindow';
import { startTestScenarioProcess, submitUserInputData } from 'redux-store/testScenarioReducers/testScenarioActions';
import { Modal, IconButton, Box, Typography, CircularProgress, Button, Divider } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';

const TestScenarioProcessManager = ({ testScenarioId, onClose, onFinishProcess, selectedEnvironment }) => {
  const kPOLLING_INTERVAL = 5000;
  const [testScenarioData, setTestScenarioData] = useState(null);
  const dispatch = useDispatch();
  const { data, error: pollingError, startPolling, stopPolling } = useTestScenarioPolling(testScenarioData?.id, kPOLLING_INTERVAL);

  const startProcess = useCallback(
    async (shouldRestart = false) => {
      try {
        const initialData = await dispatch(
          startTestScenarioProcess({ testScenarioId, startAgain: shouldRestart, environmentSetId: selectedEnvironment?.id })
        ).unwrap();
        setTestScenarioData(initialData);
        if (initialData.loading === true) {
          startPolling();
        }
      } catch (err) {
        console.log(err);
        setTestScenarioData({ error: err.error || "An unexpected error occurred. Please try again." });
      }
    },
    [dispatch, startPolling, testScenarioId]
  );


  useEffect(() => {
    setTestScenarioData((prevData) => ({
      ...prevData,
      loading: true,
    }));

    startProcess();

    return () => {
      stopPolling();
    };
  }, [startProcess, stopPolling]);

  useEffect(() => {
    if (data) {
      setTestScenarioData((prevData) => ({
        ...prevData,
        ...data,
      }));
      if (data.loading === false) {
        stopPolling();
      }
    }
    if (pollingError) {
      setTestScenarioData((prevData) => ({
        ...prevData,
        loading: false,
      }));
      setTestScenarioData({ error: pollingError.message || "An error occurred during polling." });
    }
  }, [data, pollingError, stopPolling]);

  const handleUserSubmit = async (inputData, shouldSaveMsg, shouldSaveInEnvironment) => {
    console.log("handleUserSubmit", inputData, shouldSaveMsg);
    try {
      stopPolling();
      setTestScenarioData(prevState => ({
        ...prevState,
        user_input_needed: false,
        loading: true,
        messages: [...prevState.messages, inputData],
      }));
      const response = await dispatch(submitUserInputData({
        testScenarioProcessId: testScenarioData?.id,
        messageToSend: inputData,
        saveMessage: shouldSaveMsg,
        shouldSaveInEnvironment: shouldSaveInEnvironment
      })).unwrap();
      setTestScenarioData((prevData) => ({
        ...prevData,
        ...response,
      }));

      setTimeout(() => {
        startPolling();
      }, kPOLLING_INTERVAL);
    } catch (err) {
      setTestScenarioData({ error: err.message || "Failed to submit data. Please try again." });
    }
  };

  const handleOnCloseBtnClick = () => {
    executeCloseEvent();
  };

  const handleOnChatWindowGoToTestScenarioClick = () => {
    stopPolling();
    onFinishProcess(true);
  };

  const executeCloseEvent = () => {
    stopPolling();
    onClose();
  };

  const handleStartAgainClick = () => {
    startProcess(true);
  };

  const renderErrorState = () => {
    if (!testScenarioData?.error) return null;

    return (
      <Box display="flex" flexDirection="column" alignItems="center" p={2}>
        <ErrorIcon sx={{ fontSize: 80, color: 'red', marginBottom: 1 }} />
        <Typography variant="h6" align="center" color="gray" sx={{ marginTop: 2 }}>
          {testScenarioData?.error}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleOnCloseBtnClick}
          sx={{
            marginTop: 2,
            marginBottom: 0,
            width: '80%',
            borderRadius: '8px',
          }}
        >
          Okay
        </Button>
      </Box>
    );
  };

  const renderProcessingState = () => {
    if (testScenarioData?.error) return renderErrorState();

    return (
      <ChatWindow
        onSendBtnClick={handleUserSubmit}
        testScenarioData={testScenarioData}
        onGoToTestScenarioClick={handleOnChatWindowGoToTestScenarioClick}
        startAgainClick={handleStartAgainClick}
      />
    );
  };

  const modalStyles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    p: 2,
    borderRadius: '4px',
  };

  const closeButtonStyles = {
    position: 'absolute',
    top: 8,
    right: 4,
    padding: '8px',
  };

  const headerStyles = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 1,
  };

  return (
    <Modal open onClose={handleOnCloseBtnClick}>
      <Box sx={modalStyles}>
        <Box sx={headerStyles}>
          <Typography variant="h6">
          {testScenarioData?.error ? "Error while building datasets" : "Building Datasets"}
            </Typography>
          <IconButton onClick={handleOnCloseBtnClick} sx={closeButtonStyles}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider sx={{ borderBottomWidth: 1, borderColor:'gray' }} />
        {renderProcessingState()}
      </Box>
    </Modal>
  );
};

export default TestScenarioProcessManager;
