import React, { useEffect, useState, useRef } from 'react';
import { 
  Box,
  Button,
  Paper,
  Typography,
  CircularProgress,
  Collapse,
  IconButton,
  Divider,
  Stack
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  KeyboardArrowRight,
  KeyboardArrowDown,
  AccessTime
} from '@mui/icons-material';

// Optional: Import Monaco Editor if available
let Editor;
try {
  Editor = require('@monaco-editor/react').default;
} catch (e) {
  // Monaco editor not available
}


const BuildLogs = ({
  initialFetch,
  fetchBuildLogs,
  goToAllBuilds,
  isLoading,
  hideAllBuilds,
  getStatus,
  root = false,
  isExpanded
}) => {
  const [buildLogs, setBuildLogs] = useState({});
  const [buildLogIds, setBuildLogIds] = useState([]);
  const isInitial = useRef(true);

  const getStatusColor = (statusObj, theme) => {

    let statusColor = statusObj?.status?.props?.style?.color
    if (statusColor)
    {
      return statusColor
    } else
    { 
      return theme.palette.info.main;
    }
  };

  // Log content component with Monaco Editor support
  const LogContent = ({ content }) => {
    if (!content) return null;

    // Function to preprocess and format content
    const formatContent = (str) => {
      // First try to detect if it's using => notation
      if (str.includes('=>')) {
        // Replace => with : to make it valid JSON
        str = str.replace(/=>/g, ':');
      }

      // Remove extra spaces and normalize quotes
      str = str.replace(/\\"/g, '"').replace(/"{2,}/g, '"');

      try {
        // Try to parse the string as JSON
        let obj;
        if (str.trim().startsWith('{')) {
          obj = JSON.parse(str);
        } else {
          // If it doesn't start with {, try wrapping it
          obj = JSON.parse(`{${str}}`);
        }
        // If successful, return pretty-printed JSON
        return JSON.stringify(obj, null, 2);
      } catch (e) {
        console.log('JSON parsing failed:', e);
        // If still not valid JSON, try to format it manually
        try {
          // Split by commas and format each key-value pair
          const pairs = str.split(',').map(pair => pair.trim());
          const formatted = pairs.join(',\n');
          return formatted;
        } catch (err) {
          // If all formatting attempts fail, return original string
          return str;
        }
      }
    };

    const formattedContent = formatContent(content);

    // If Monaco Editor is available, use it
    if (Editor) {
      return (
        <Box sx={{ minHeight: 200, width: '100%' }}>
          <Editor
            value={formattedContent}
            language="json"
            theme="vs-dark"
            options={{
              readOnly: true,
              minimap: { enabled: false },
              lineNumbers: (lineNumber) => lineNumber.toString().padStart(2, ' '),
              renderLineHighlight: 'none',
              scrollBeyondLastLine: false,
              fontSize: 14,
              fontFamily: 'Consolas, Monaco, "Courier New", monospace',
              formatOnPaste: true,
              formatOnType: true,
              autoIndent: 'full',
              wordWrap: 'on'
            }}
          />
        </Box>
      );
    }

    // Fallback to basic display with line numbers
    const lines = formattedContent.split('\n').filter(line => line.trim() !== ''); // Remove empty lines
    return (
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'Consolas, Monaco, "Courier New", monospace',
        fontSize: '14px',
        lineHeight: '1.5',
        p: 2,
        bgcolor: '#1e1e1e',
        color: '#d4d4d4'
      }}>
        {lines.map((line, idx) => (
          <Box key={idx} sx={{ display: 'flex', py: 0.5 }}>
            <Box sx={{ 
              color: '#858585',
              minWidth: '3em',
              mr: 2,
              textAlign: 'right',
              userSelect: 'none',
              fontFamily: 'Consolas, Monaco, "Courier New", monospace'
            }}>
              {(idx + 1).toString().padStart(2, ' ')}
            </Box>
            <Box sx={{ flex: 1, whiteSpace: 'pre-wrap' }}>
              {line}
            </Box>
          </Box>
        ))}
      </Box>
    );
  };

  const BuildLogStep = ({ logId, depth = 0, isLastLevel = false }) => {
    const log = buildLogs[logId];
    if (!log) return null;

    const statusObj = getStatus(log?.status);
    const currentStatus = statusObj?.status || '';
    const statusIconComponent = statusObj?.statusIcon;
    const hasChildren = log.has_child_logs;
    const isExpanded = log.isExpanded;
    
    // Always show dropdown if there are children, regardless of level
    const showDropdown = hasChildren;
    const nextIsLast = hasChildren && !buildLogs[log.childLogIds[0]]?.has_child_logs;
    const showEditorStyle = isLastLevel;

    const handleExpand = (e) => {
      e.stopPropagation();
      onExpandLog(e, logId);
    };

    return (
      <Paper
        elevation={0}
        sx={{
          mb: showEditorStyle ? 0 : 2,
          border: '1px solid',
          borderColor: theme => depth > 0 ? '#e0e0e0' : getStatusColor(statusObj, theme),
          borderRadius: '4px',
          overflow: 'hidden',
          ml: depth > 0 ? 4 : 0,
          mr: depth > 0 ? 4 : 0,
          bgcolor: showEditorStyle ? '#1e1e1e' : 'background.paper',
        }}
      >
        <Box 
          sx={{ 
            p: 2,
            cursor: showDropdown ? 'pointer' : 'default'
          }}
          onClick={showDropdown ? handleExpand : undefined}
        >
          <Stack direction="row" spacing={2} alignItems="center">
            {showDropdown && (
              <IconButton
                size="small"
                sx={{ color: showEditorStyle ? '#d4d4d4' : 'inherit' }}
              >
                {isExpanded ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
              </IconButton>
            )}
            
            <Box sx={{ flexGrow: 1 }}>
              <Typography 
                variant="body1" 
                sx={{ 
                  fontWeight: depth === 0 ? 500 : 400,
                  color: showEditorStyle ? '#d4d4d4' : 'text.primary'
                }}
              >
                {log.log_message}
              </Typography>
            </Box>

            {!showEditorStyle && (
              <Stack direction="row" spacing={2} alignItems="center">
                {log.execution_time > 0 && (
                  <>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <AccessTime fontSize="small" sx={{ color: 'text.secondary' }} />
                      <Typography variant="body2" color="text.secondary">
                        {log.execution_time.toFixed(2)}s
                      </Typography>
                    </Stack>
                    <Divider orientation="vertical" flexItem />
                  </>
                )}
                <Stack direction="row" spacing={1} alignItems="center">
                  {statusIconComponent}
                  <Typography variant="body2" color="text.secondary">
                    {currentStatus}
                  </Typography>
                </Stack>
              </Stack>
            )}
          </Stack>
        </Box>

        <Collapse in={isExpanded && hasChildren}>
          {hasChildren && (
            <Box 
              sx={{ 
                ...(nextIsLast && {
                  bgcolor: '#1e1e1e',
                  color: '#d4d4d4',
                  p: 0,
                  mx: 0,
                })
              }}
            >
              {log.childLogIds?.map((childId) => {
                const childLog = buildLogs[childId];
                if (!childLog) return null;

                if (nextIsLast) {
                  return (
                    <LogContent 
                      key={childId} 
                      content={childLog.log_message}
                    />
                  );
                }

                return (
                  <BuildLogStep 
                    key={childId} 
                    logId={childId} 
                    depth={depth + 1}
                    isLastLevel={nextIsLast}
                  />
                );
              })}
            </Box>
          )}
        </Collapse>
      </Paper>
    );
  };

  useEffect(() => {
    if (initialFetch && isInitial.current) {
      fetchLogs();
    }
  }, [initialFetch]);

  const fetchLogs = async (parentLogId) => {
    const response = await fetchBuildLogs(parentLogId);
    if (response) {
      setParsedBuildLogs(response, parentLogId);
    }
  };

  const updateBuildLogs = (logs) => {
    setBuildLogs(logs);
  };

  const setParsedBuildLogs = async (logs, parentLogId) => {
    const logHash = { ...buildLogs };
    const logIds = logs.map((log) => {
      const logObj = { ...log, isExpanded: false };
      if (log.has_child_logs) {
        logObj.childLogIds = [];
      }
      logHash[log.id] = { ...logObj };
      return log.id;
    });
    
    if (parentLogId) {
      logHash[parentLogId] = {
        ...logHash[parentLogId],
        childLogIds: [...logIds],
        isExpanded: true
      };
    }
    
    updateBuildLogs(logHash);
    if (isInitial.current) {
      setBuildLogIds(logIds);
      isInitial.current = false;
    }
  };

  const refreshLogs = () => {
    isInitial.current = true;
    fetchLogs();
  };

  const onExpandLog = (event, parentLogId) => {
    event.stopPropagation();
    if (buildLogs?.[parentLogId]?.isExpanded) {
      updateBuildLogs({
        ...buildLogs,
        [parentLogId]: { ...buildLogs[parentLogId], isExpanded: false }
      });
    } else if (buildLogs?.[parentLogId]?.has_child_logs) {
      const childLogIds = buildLogs?.[parentLogId]?.childLogIds || [];
      const lastChildId = childLogIds[childLogIds.length - 1];
      if (lastChildId && buildLogs[lastChildId]) {
        updateBuildLogs({
          ...buildLogs,
          [parentLogId]: { ...buildLogs[parentLogId], isExpanded: true }
        });
      } else {
        fetchLogs(parentLogId);
      }
    }
  };

  if (isInitial.current || !buildLogIds) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (buildLogIds && buildLogIds.length === 0) {
    return (
      <Box sx={{ width: '100%' }}>
        <Typography sx={{ mb: 2 }}>
          Logs are getting ready, try refreshing!
        </Typography>
        {!hideAllBuilds && (
          <Button
            variant="outlined"
            onClick={goToAllBuilds}
          >
            All Builds
          </Button>
        )}
      </Box>
    );
  }

  return (
    <Box sx={{ width: '100%' }}>
      {!hideAllBuilds && (
        <Button
          variant="outlined"
          onClick={goToAllBuilds}
          sx={{ mb: 2 }}
        >
          All Builds
        </Button>
      )}

      <Box sx={{ maxHeight: 600, overflow: 'auto' }}>
        {buildLogIds.map((id) => (
          <BuildLogStep key={id} logId={id} depth={0} />
        ))}
      </Box>
    </Box>
  );
};

export default BuildLogs;