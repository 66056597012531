import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Box} from '@mui/material';
import CustomTable from "../Common/CustomTable";
import { useSnackbar } from "../../contexts/CustomSnackbarContext";
import useSearchParams from "../../hooks/useSearchParams";
import {
  getChatList
} from '../../redux-store/chatsReducers/chatsActions';
import MainLayout from '../../layouts/MainLayout';

const ChatsList = function ({ project }) {
  const { openSnackbar } = useSnackbar();
  const searchParams = useSearchParams();
  const dispatch = useDispatch();
  const page = parseInt(searchParams.get('page') || '1');
  const [isLoading, setIsLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(isNaN(page) ? 1 : page);
  const [chatsList, setChatsList] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [totalChats, setTotalChats] = useState(0);
  const history = useHistory();
  useEffect(() => {
    fetchChatList();
    window.history.pushState('', '', `?page=${pageNumber}`);
  }, [pageNumber]);

  const onClick = (chatId) => {
    history.push(`/search/${chatId}`);
  }

  const fetchChatList = async () => {
    setIsLoading(true);
    const chatListResponse = await dispatch(
      getChatList({
        projectId: project.id,
        pageNumber: pageNumber,
        pageSize: 10
      })
    );

    if (chatListResponse?.payload) {
      setChatsList([...chatListResponse.payload?.chats] || []);
      setTotalPages(chatListResponse.payload?.total_pages);
      setTotalChats(chatListResponse.payload?.total);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      openSnackbar({
        message: "Something went wrong. Please try again!",
        severity: "error",
      });
    }
  };

  const onPageChange = (e, value) => {
    setPageNumber(value);
  };

  const renderList = () => {
    if (chatsList.length === 0) {
      return (
        <Box sx={{width: "100%", textAlign: "center", padding: "50px 32px"}}>
          <h3>No results found.</h3>
        </Box>
      )
    }
    return (
      <CustomTable
        headers={[
          {
            id: 'title',
            type: 'text',
            text: 'Title',
            sx: { width: 'calc(100% - 540px)', padding: "18px 20px !important"},
          },
          {
            id: 'ipAddress',
            type: 'text',
            text: 'IP Address',
            sx: { width: '180px', padding: "18px 20px !important" }
          },
          {
            id: 'email',
            type: 'text',
            text: 'Email',
            sx: { width: '180px', padding: "18px 20px !important" }
          },
          {
            id: 'time',
            type: 'text',
            text: 'Date Time',
            sx: { width: '180px', padding: "18px 20px !important" }
          }
        ]}
        rows={chatsList.map(
          ({
            id,
            first_message,
            email,
            started_at,
            ip_address
          }) => ({
            id,
            cells: [
              {
                id: id,
                type: 'text',
                text: first_message,
                sx: { width: 'calc(100% - 540px)', cursor: 'pointer', padding: "18px 20px !important" },
                onClick: () => {
                  onClick(id)
                },
              },
              {
                id: "ipAddress-"+id,
                type: 'text',
                text: ip_address ?? "",
                sx: { width: '180px', cursor: 'pointer', padding: "18px 20px !important" },
                onClick: () => {
                  onClick(id)
                },
              },
              {
                id: id,
                type: 'email-'+id,
                text: email ?? "",
                sx: { width: '180px', cursor: 'pointer', padding: "18px 20px !important" },
                onClick: () => {
                  onClick(id)
                },
              },
              {
                id: id,
                type: 'dataTime-'+id,
                text: started_at ? new Date(started_at * 1000).toString() : "",
                sx: { width: '180px', cursor: 'pointer', padding: "18px 20px !important" },
                onClick: () => {
                  onClick(id)
                },
              },
            ],
          })
        )}
      />
    )
  };

  return (
    <MainLayout
      isLoading={isLoading}
      headerText="Search"
      subtitleText={`Showing ${totalChats || 0} search(es)`}
      totalPages={totalPages}
      pageNumber={pageNumber}
      onPageChange={onPageChange}
    >
      <Box sx={{maxHeight: "calc(100vh - 180px)", overflow: "auto", px: 1}}>
        {
          !isLoading && (
            renderList()
          )
        }
      </Box>
    </MainLayout>
  );
};

export default ChatsList;
