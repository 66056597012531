import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import TabsComponent from "./TabsComponent";
import APIDocs from "./APIDocs";
import EntityResponse from "./EntityResponse";
import EntityVariables from "./EntityVariables";
import dayjs from "dayjs";

const TestCaseSetEntityResponse = ({ testCase, isExpanded, selectedEnvironment }) => {
  const [activeTab, setActiveTab] = useState(0);
  const tabLabels = ["Req / Resp", "Variables", "Api Docs"];
  const handleTabChange = (event, tabIndex) => {
    setActiveTab(tabIndex);
  };
  const renderTabContent = () => {
    switch (activeTab) {
      case 1:
        return <EntityVariables testCaseSetId={testCase?.id} selectedEnvironment={selectedEnvironment} testCaseSet={testCase} />;
      case 2:
        return <APIDocs apiDocs={testCase?.entity?.summary?.overview} />;
      default:
        return <EntityResponse response={testCase?.test_case_set_response} />;
    }
  };

  const renderEnvironmentName = () => {
    if(activeTab !== 0) return null;
    return (
      <Typography sx={{ mt: 2}}>
        <Typography>
          <Typography component="span" sx={{ fontWeight: 'bold', textTransform: 'uppercase' }}>
            {testCase?.test_case_set_response.request_type}{' '}
          </Typography>
          {testCase?.test_case_set_response.request_url}
        </Typography>
        <Typography>
          <Typography component="span" sx={{ fontWeight: 'bold' }}>
            Environment Used:{' '}
          </Typography>
          {testCase?.test_case_set_response.environment_set?.title}
        </Typography>
        <Typography>
          <Typography component="span" sx={{ fontWeight: 'bold' }}>
            Fetched At:{' '}
          </Typography>
          {testCase?.test_case_set_response?.created_at ? dayjs(testCase?.test_case_set_response?.created_at * 1000).fromNow() : 'NA'}
        </Typography>
        <Typography>
          <Typography component="span" sx={{ fontWeight: 'bold' }}>
            Status:{' '}
          </Typography>
          {testCase?.test_case_set_response.status}
        </Typography>
      </Typography>
    );
  };

  return (
    <Box
      sx={{
        p: 2,
        bgcolor: "white",
        borderRadius: "8px",
        width: "calc(100% - 16px)",
        ml: "16px",
      }}
    >
      <TabsComponent
        tabLabels={tabLabels}
        activeTab={activeTab}
        handleTabChange={handleTabChange}
      />
      <Typography sx={{ mt: 2, mb: -2 }}>
        {renderEnvironmentName()}
      </Typography>
      {renderTabContent()}
    </Box>
  );
};

export default TestCaseSetEntityResponse;
