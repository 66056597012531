import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { CircularProgress, Box } from '@mui/material';
import { useSnackbar } from '../../contexts/CustomSnackbarContext';
import { autoLogin } from 'redux-store/currentUserActions';

const AutoLoginComponent = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { openSnackbar } = useSnackbar();

  useEffect(() => {
    const performAutoLogin = async () => {
      const queryParams = new URLSearchParams(location.search);
      const token = queryParams.get('token');

      if (!token) {
        openSnackbar({ message: 'Auto login token is missing', severity: 'error' });
        history.push('/login');
        return;
      }

      try {
        const response = await dispatch(autoLogin(token)).unwrap();
        
        if (response.message === 'Login successful') {
          if (response.has_organisation_or_project) {
            history.push('/collections');
          } else {
            history.push('/home');
          }
        } else {
          throw new Error('Auto login failed');
        }
      } catch (error) {
        history.push('/login');
      }
    };

    performAutoLogin();
  }, [dispatch, history, location, openSnackbar]);

  return (
    <Box 
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default AutoLoginComponent;