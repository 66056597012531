import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from '@mui/material';

const CollisionDialog = ({ open, onClose, onDecision, collectionName }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ fontFamily: "Inter, Sans-serif", fontWeight: "500" }}>
        Already Exists
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ fontFamily: "Inter, Sans-serif", fontWeight: "500" }}>
          A collection named "{collectionName}" already exists. Would you like to override the existing collection or create a new one?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button 
          onClick={() => onDecision(false)} 
          sx={{ fontFamily: "Inter, Sans-serif", fontWeight: "500" }}
        >
          Override Existing
        </Button>
        <Button 
          onClick={() => onDecision(true)}
          sx={{ fontFamily: "Inter, Sans-serif", fontWeight: "500" }}
          autoFocus
        >
          Create New
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CollisionDialog;