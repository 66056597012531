import React, { useEffect, useState } from 'react';
import { Tabs, Tab, Modal, Button, IconButton, Box, FormControlLabel, Checkbox, Typography, Link, Snackbar, Alert, Select, MenuItem } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import { updateEntity } from '../../redux-store/currentUserActions';
import { useDispatch } from "react-redux";
import { useGuestUser } from '../../contexts/GuestUserContext';
import LinkIcon from '@mui/icons-material/Link';
import { getAllVariableSets } from 'redux-store/variableSetReducers/variableSetActions';
import { useSelector } from 'react-redux';

const ApiOptions = ({ state, selectedIcon, api, onSelectEnvironment }) => {
  const { isGuestUser } = useGuestUser();
  const [selectedOption, setSelectedOption] = useState("option2");
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const [settings, setSettings] = useState({
    healthCheck: api?.health_check || false,
    autoDocumentation: api?.auto_documentation || false,
    enableMonitoring: api?.enable_monitoring || false,
  });
  const dispatch = useDispatch();
  const [shouldShowSnackBar, setShouldShowSnackBar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [selectedEnvironment, setSelectedEnvironment] = useState('');
  const [environments, setEnvironments] = useState([]);
  const project = useSelector((state) => state.common.selectedProject);


  const titleParts = [];
  if (state?.collection?.name) titleParts.push(state.collection.name);
  if (state?.folder) titleParts.push(state.folder.name);
  if (state?.collectionApi) titleParts.push(state.collectionApi.name);
  if (state?.folderApi) titleParts.push(state.folderApi.name);

  const handleOptionChange = (option) => {
    setSelectedOption(option);
    selectedIcon(option);
  };

  const handleSettingsChange = (e) => {
    const { name, value, type, checked } = e.target;
    setSettings({ ...settings, [name]: type === 'checkbox' ? checked : value });
  };

  const toggleSettingsModal = () => {
    setIsSettingsModalOpen(!isSettingsModalOpen);
  };

  const handleSettingsSubmit = (e) => {
    e.preventDefault();
    dispatch(updateEntity({ entity: { health_check: settings?.healthCheck, auto_documentation: settings.autoDocumentation, enable_monitoring: settings.enableMonitoring }, id: api.id }));
    toggleSettingsModal();
  };

  const handleEnvironmentChange = (event) => {
    setSelectedEnvironment(event.target.value);
    onSelectEnvironment(event.target.value);
  };

  const tabs = [
    { value: "option1", label: "Overview", disabled: false },
    { value: "option2", label: "Request", disabled: false },
    { value: "option3", label: "Collected Samples", disabled: false }
  ];

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: '5px',
    p: 4,
  };

  const handleCloseSnackbar = () => {
    setShouldShowSnackBar(false); 
  };

  const onLinkClick = (e) => {
    e.preventDefault(); 
    navigator.clipboard.writeText(window.location.href); 
    setShouldShowSnackBar(true);
    setSnackbarMessage('Link copied to clipboard');
  };  

  useEffect(() => {
    const fetchEnvironments = async () => {
      try {
        const projectId = project.id;
        if (projectId) {
          const result = await dispatch(getAllVariableSets({ 
            projectId: projectId,
            isEnvironment: true 
          })).unwrap();
          console.log('result:', result);
          if (result?.variable_sets) {
            setEnvironments(result.variable_sets);
          }
        }
      } catch (error) {
        console.error('Error fetching environments:', error);
      }
    };

   
    fetchEnvironments();
    
  }, [project.id]);

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingRight: '10px', alignItems: 'center', marginTop:'2px' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Tabs
            className='tab-style'
            value={selectedOption}
            onChange={(event, newValue) => handleOptionChange(newValue)}
            TabIndicatorProps={{ style: { display: "none" } }}
            sx={{ marginLeft: '10px', minHeight: '28px', height: '28px' }}
          >
            {tabs.map(tab => (
              <Tab
                disabled={isGuestUser && tab.value === "option3"}
                key={tab.value}
                value={tab.value}
                label={tab.label}
                sx={{
                  minHeight: '28px',
                  height: '28px',
                  textTransform: 'none',
                  color: selectedOption === tab.value ? "#fff !important" : "#575757",
                  backgroundColor: selectedOption === tab.value ? '#6241D4' : '#e9e9e9',
                  fontSize: "10px",
                  fontFamily: "Inter, Sans-serif",
                  fontWeight: '700',
                }}
              />
            ))}
          </Tabs>

          <Typography 
            sx={{ 
              display: 'flex', 
              alignItems: 'center',
              marginLeft: '0px',
              fontSize: '10px', 
              fontWeight: '300', 
            }}
          >
            {titleParts.map((part, index) => (
              <span key={index} style={{fontSize:11, fontWeight: index === titleParts.length - 1 ? '800' : '600', marginLeft: '5px'}}>
                {index > 0 && ' / '}
                {part}
              </span>
            ))}
            <Link 
              href="#" 
              onClick={onLinkClick}
            >
              <LinkIcon sx={{ fontSize: '1.4rem', verticalAlign: 'middle', marginLeft: '8px' }} />
            </Link>
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Select
            value={selectedEnvironment}
            onChange={handleEnvironmentChange}
            size="small"
            displayEmpty
            sx={{
              height: '28px',
              '& .MuiSelect-select': {
                fontSize: '10px',
                fontFamily: "Inter, Sans-serif",
                padding: '4px 8px',
              }
            }}
          >
            <MenuItem value="" sx={{ fontSize: '10px', fontFamily: "Inter, Sans-serif" }}>
              Select Environment
            </MenuItem>
            {environments && environments.map((env) => (
              <MenuItem 
                key={env.id} 
                value={env.id}
                sx={{ fontSize: '10px', fontFamily: "Inter, Sans-serif" }}
              >
                {env.title}
              </MenuItem>
            ))}
          </Select>

          {api?.id && (
            <IconButton onClick={toggleSettingsModal}>
              <SettingsIcon />
            </IconButton>
          )}
        </Box>
      </Box>

      <Snackbar 
        open={shouldShowSnackBar} 
        onClose={handleCloseSnackbar} 
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }} 
        autoHideDuration={5000}
      >
        <Alert onClose={handleCloseSnackbar} severity="info">
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <Modal open={isSettingsModalOpen} onClose={toggleSettingsModal}>
        <Box sx={modalStyle}>
          <form onSubmit={handleSettingsSubmit}>
            <FormControlLabel
              control={<Checkbox checked={settings.healthCheck} onChange={handleSettingsChange} name="healthCheck" />}
              label="Health Check"
              sx={{ marginTop: '20px', span: { fontFamily: "Inter, Sans-serif", fontWeight: "500", fontSize: "12px" } }}
            />
            <FormControlLabel
              control={<Checkbox checked={settings.autoDocumentation} onChange={handleSettingsChange} name="autoDocumentation" />}
              label="Auto Documentation"
              sx={{ marginTop: '20px', span: { fontFamily: "Inter, Sans-serif", fontWeight: "500", fontSize: "12px" } }}
            />
            <FormControlLabel
              control={<Checkbox checked={settings.enableMonitoring} onChange={handleSettingsChange} name="enableMonitoring" />}
              label="Enable Monitoring"
              sx={{ marginTop: '20px', span: { fontFamily: "Inter, Sans-serif", fontWeight: "500", fontSize: "12px" } }}
            />
            <Button 
              type="submit" 
              variant="contained" 
              style={{ marginTop: '20px', display: 'block', width: '100%', fontFamily: "Inter, Sans-serif", fontWeight: "500", fontSize: "11px" }}
            >
              Save Settings
            </Button>
          </form>
        </Box>
      </Modal>
    </>
  );
};

export default ApiOptions;