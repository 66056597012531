import React, {useEffect, useState} from 'react';
import {
  Box, 
  Tooltip, 
  Typography, 
  TextField, 
  IconButton, 
  MenuItem, 
  Select, 
  FormControl,
  Button
} from '@mui/material';
import {
  ArticleOutlined, 
  DeleteOutline, 
  ExpandMore, 
  ChevronRight, 
  DragIndicator, 
  ContentCopyOutlined, 
  MoreVert,
  TimerOutlined,
  KeyboardArrowDown,
  CheckCircleOutlined,
  CancelOutlined
} from '@mui/icons-material';
import CustomMenu from '../Common/CustomMenu';
import EntitySelection from './EntitySelection';

const TestCaseSetHeader = ({ 
  testCaseSet = {},
  isExpanded = false,
  collections = [],
  entities = [],
  entity = null,
  collection = null,
  onToggleExpand = () => {},
  onSaveTitle = () => {},
  onClickClone = () => {},
  onClickDelete = () => {},
  onCollectionChange = () => {},
  onEntityChange = () => {},
  onDelayChange = () => {},
  disableActions = false,
  status = null
}) => {
  const [anchorEls, setAnchorEls] = useState({});
  const [title, setTitle] = useState("");
  const [isEdit, setIsEdit] = useState(testCaseSet?.isNew || false);
  const [delay, setDelay] = useState(testCaseSet?.delay_seconds ?? 0);

  useEffect(() => {
    setTitle(testCaseSet?.title || "");
    setDelay(testCaseSet?.delay_seconds ?? 0);
  }, [testCaseSet]);

  const handleMenuClick = (event, id) => {
    event.stopPropagation();
    setAnchorEls((prev) => ({ ...prev, [id]: event.currentTarget }));
  };

  const handleClose = (id) => {
    setAnchorEls((prev) => ({ ...prev, [id]: null }));
  };

  const onChange = (e) => {
    const newValue = e.target.value;
    setTitle(newValue);
    if (testCaseSet?.isNew) {
      onSaveTitle(newValue);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      if (title.trim()) {
        onSaveTitle(title.trim());
        setIsEdit(false);
      }
    } else if (e.key === "Escape") {
      setTitle(testCaseSet?.title || "");
      setIsEdit(false);
    }
  };

  const handleDelayChange = (event) => {
    event.stopPropagation();
    const newDelay = event.target.value;
    setDelay(newDelay);
    onDelayChange(newDelay);
  };

  const handleExpandClick = (event) => {
    event.stopPropagation();
    onToggleExpand();
  };

  const renderStatusIcon = () => {
    if (!status) return null;

    return (
      <Box sx={{ 
        display: 'inline-flex', 
        alignItems: 'baseline',
        marginRight: '4px',
        position: 'relative',
        width: '16px',
        height: '16px',
        marginBottom: '4px'
      }}>
        {status === 'pass' ? (
          <CheckCircleOutlined 
            sx={{ 
              width: '16px',
              height: '16px',
              color: '#2e7d32'
            }}
          />
        ) : (
          <CancelOutlined 
            sx={{ 
              width: '16px',
              height: '16px',
              color: '#d32f2f'
            }}
          />
        )}
      </Box>
    );
  };

  const delayOptions = [0,1,5,10,20,30,45,60,90,120,180,240,300];
  const buttonColor = disableActions ? 'rgba(0, 0, 0, 0.26)' : '#2196f3';

  const renderDelayValue = (selected) => {
    return (
      <Typography sx={{ display: 'flex', alignItems: 'center' }}>
        <TimerOutlined sx={{ fontSize: 16, mr: 0.5 }} />
        {selected === 0 ? '0s' : `Delay: ${selected}s`}
      </Typography>
    );
  };

  return (
    <Box 
      sx={{
        width: "100%",
        cursor: 'default' 
      }} 
      className={`testCaseSetHeader ${anchorEls[testCaseSet?.id] && "active"} ${isExpanded && "expanded"}`}
    >
      <div style={{display: "inline-block", verticalAlign: "top", margin: "24px 8px 0 0"}} className="dragIcon">
        <DragIndicator />
      </div>
      <div style={{display: "inline-block", width: "calc(100% - 150px)"}}>
        <div>
          <div style={{
            width: "100%", 
            display: "flex", 
            alignItems: "flex-end",
          }}>
            {renderStatusIcon()}
            {isEdit ? (
              <Box 
                onClick={(e) => e.stopPropagation()} 
                sx={{width: "100%"}}
              >
                <TextField 
                  sx={{width: "100%", outline: "none"}} 
                  size="small" 
                  placeholder="Add title" 
                  value={title}
                  onKeyDown={handleKeyDown}
                  onChange={onChange}
                  autoFocus
                />
              </Box>
            ) : (
              <Tooltip title={testCaseSet?.title || "Add a title"} placement="top-start">
                <Typography 
                  className="truncate-1-lines" 
                  sx={{
                    p: 1, 
                    pl: 0, 
                    height: "30px", 
                    width: "100%", 
                    fontSize: "14px", 
                    wordBreak: "break-all",
                    cursor: 'pointer' 
                  }} 
                  onClick={() => setIsEdit(true)}
                >
                  {testCaseSet?.title || "Add a title"}
                </Typography>
              </Tooltip>
            )}
          </div>
          <div style={{width: "100px", position: "absolute", right: "16px", display: "inline-block"}}>
            <IconButton 
              id={`actions-btn-${testCaseSet?.id}`} 
              onClick={(event) => handleMenuClick(event, testCaseSet?.id)} 
              aria-label="actions" 
              sx={{
                width: "50px", 
                verticalAlign: "top", 
                display: "inline",
                cursor: 'pointer' 
              }} 
              className="menuIcon"
            >
              <MoreVert />
            </IconButton>
          </div>
        </div>
        
        <div style={{width: "fit-content", marginTop: "8px", marginBottom: "16px"}}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <EntitySelection
              collections={collections}
              entities={entities}
              selectedEntity={entity}
              selectedCollection={collection}
              onCollectionChange={onCollectionChange}
              onEntityChange={onEntityChange}
              isDisabled={!!entity}
            />
            <Tooltip 
              title="Sets wait time between test cases. Adds delay after test case completes."
              placement="top"
              arrow
            >
              <FormControl size="small" sx={{ minWidth: 120 }}>
                <Select
                  value={delay}
                  onChange={handleDelayChange}
                  displayEmpty
                  renderValue={renderDelayValue}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 300
                      }
                    }
                  }}
                  sx={{
                    cursor: 'pointer' 
                  }}
                >
                  {delayOptions.map((seconds) => (
                    <MenuItem 
                      key={seconds} 
                      value={seconds}
                      sx={{ cursor: 'pointer' }} 
                    >
                      {seconds}s
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Tooltip>
          </Box>
        </div>

        <Button
          onClick={handleExpandClick}
          disabled={disableActions}
          endIcon={isExpanded ? <ExpandMore sx={{ color: buttonColor }} /> : <ChevronRight sx={{ color: buttonColor }} />}
          variant="text"
          size="small"
          sx={{
            position: 'relative',
            textTransform: 'none',
            color: buttonColor,
            padding: '0px',
            boxShadow: 'none',
            fontWeight: 600,
            cursor: disableActions ? 'default' : 'pointer', 
            '&:hover': {
              backgroundColor: 'rgba(33, 150, 243, 0.04)',
            },
            '& .MuiButton-endIcon': {
              ml: 0,
              '& > *:first-of-type': {
                fontSize: 16
              }
            }
          }}
        >
          {isExpanded ? 'Collapse' : 'Expand'}
        </Button>

        <CustomMenu
          labelledBy={`actions-btn-${testCaseSet?.id}`}
          anchorEl={anchorEls[testCaseSet?.id]}
          onClose={() => handleClose(testCaseSet?.id)}
        >
          <div style={{ padding: '14px' }}>
            <div 
              onClick={(event) => {
                event.stopPropagation();
                onClickClone(); 
                handleClose(testCaseSet?.id);
              }} 
              style={{marginBottom: "8px", cursor: "pointer"}}
            >
              <ContentCopyOutlined sx={{mr: 1}} />Clone
            </div>
            <div 
              onClick={(event) => {
                event.stopPropagation();
                onClickDelete(); 
                handleClose(testCaseSet?.id);
              }} 
              style={{cursor: "pointer"}}
            >
              <DeleteOutline sx={{mr: 1}} />Delete
            </div>
          </div>
        </CustomMenu>
      </div>
    </Box>
  );
};

export default TestCaseSetHeader;