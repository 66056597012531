import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import
{
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  IconButton,
  Typography,
  Avatar,
  Chip,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Stack,
  Tooltip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import
{
  MoreVert as MoreVertIcon,
  Warning as WarningIcon,
  Inventory as InventoryIcon
} from '@mui/icons-material';

import { createBuild } from '../../redux-store/testScenarioBuildReducers/testScenarioBuildActions';
import { setSelectedTestScenarios } from "../../redux-store/testScenarioReducers/testScenarioSlice";
import CustomDialog from '../Common/CustomDialog';

dayjs.extend(relativeTime);

// Styled components
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(1, 1.5), // Consistent base padding
  borderBottom: `1px solid ${theme.palette.divider}`,
  '&.checkbox-cell': {
    width: '32px',
    padding: theme.spacing(0.5),
  },
  '&.date-cell': {
    width: '140px', // Fixed width for date column
    padding: theme.spacing(1, 1.5),
    whiteSpace: 'nowrap',
  },
  '&.action-cell': {
    width: '48px',
    padding: theme.spacing(0.5),
  }
}));

const StyledTableHeaderCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(1, 1.5),
  backgroundColor: theme.palette.grey[50],
  fontWeight: 600,
  fontSize: '1rem',
  color: theme.palette.text.primary,
  borderBottom: `2px solid ${theme.palette.divider}`,
  '&.checkbox-cell': {
    width: '32px',
    padding: theme.spacing(0.5),
  },
  '&.date-cell': {
    width: '140px', // Fixed width for date column
    padding: theme.spacing(1, 1.5),
  },
  '&.action-cell': {
    width: '48px',
    padding: theme.spacing(0.5),
  }
}));

const StatusChip = styled(Chip)(({ theme, status }) =>
{
  const colors = {
    active: theme.palette.success.main,
    draft: theme.palette.warning.main,
    archived: theme.palette.grey[600],
    failed: theme.palette.error.main,
    running: theme.palette.info.main,
  };
  return {
    color: colors[status?.toLowerCase()] || theme.palette.grey[600],
    backgroundColor: 'transparent',
    '& .MuiChip-label': {
      fontWeight: 500,
    },
  };
});

const UserAvatar = styled(Avatar)(({ theme }) => ({
  width: 32,
  height: 32,
  backgroundColor: theme.palette.primary.main,
  fontSize: '0.875rem',
  fontWeight: 500,
  cursor: 'pointer',
}));

const TagChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  borderRadius: theme.shape.borderRadius,
  height: 20, // Reduced height
  '& .MuiChip-label': {
    fontSize: '0.75rem',
    padding: '4px 8px', // Reduced horizontal padding
  },
}));

const TestScenariosList = function ({ testScenarios, onDelete, onArchive, onMarkActive })
{
  const dispatch = useDispatch();
  const history = useHistory();
  const isLoading = useSelector((state) => state.testScenario.isLoading);
  const environmentSets = useSelector((state) => state.variableSet.allVariableSets);

  const [checkedTestScenarios, setCheckedTestScenarios] = useState([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteTestScenarioId, setDeleteTestScenarioId] = useState('');
  const [buildTestId, setBuildTestId] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuId, setMenuId] = useState(null);

  const allChecked = testScenarios.length === checkedTestScenarios.length;

  useEffect(() =>
  {
    updateCheckedTestScenariosIds([]);
  }, [testScenarios]);

  const getInitials = (name) =>
  {
    if (!name) return '';
    return name.split(' ')
      .map(word => word[0])
      .join('')
      .toUpperCase();
  };

  const handleMenuClick = (event, id) =>
  {
    setAnchorEl(event.currentTarget);
    setMenuId(id);
  };

  const handleMenuClose = () =>
  {
    setAnchorEl(null);
    setMenuId(null);
  };

  const handleAllCheckboxChange = () =>
  {
    if (allChecked)
    {
      updateCheckedTestScenariosIds([]);
    } else
    {
      updateCheckedTestScenariosIds(testScenarios.map((test) => test.id));
    }
  };

  const handleCheckboxChange = (e, tId) =>
  {
    if (e.target.checked)
    {
      updateCheckedTestScenariosIds([...checkedTestScenarios, tId]);
    } else
    {
      updateCheckedTestScenariosIds(checkedTestScenarios.filter((id) => id !== tId));
    }
  };

  const updateCheckedTestScenariosIds = async (scenarioIds) =>
  {
    setCheckedTestScenarios(scenarioIds);
    const selectedTestScenarios = testScenarios
      .filter((test) => scenarioIds.indexOf(test.id) !== -1)
      .map((test) => ({
        title: test.title,
        id: test.id,
        status: test.status
      }));
    await dispatch(setSelectedTestScenarios([...selectedTestScenarios]));
  };

  const onClick = (e, id) =>
  {
    history.push(`/test-scenarios/${id}`);
  };

  const onBuild = async (envId) =>
  {
    const buildResponse = await dispatch(
      createBuild({ testScenarioId: buildTestId, environmentSetId: envId })
    );
    history.push(`/test-scenarios/${buildTestId}/build/${buildResponse.payload.id}`);
    setBuildTestId('');
  };

  const onViewBuilds = (id) =>
  {
    history.push(`/test-scenarios/${id}/build/`);
    handleMenuClose();
  };

  const onClickDelete = (testScenarioId) =>
  {
    setDeleteTestScenarioId(testScenarioId);
    setDeleteDialogOpen(true);
    handleMenuClose();
  };

  const onDeleteConfirm = () =>
  {
    onDelete(deleteTestScenarioId);
    setDeleteDialogOpen(false);
    setDeleteTestScenarioId('');
  };

  const onArchiveConfirm = () =>
  {
    onArchive(deleteTestScenarioId);
    setDeleteDialogOpen(false);
    setDeleteTestScenarioId('');
  };


  const renderTags = (tags = []) =>
  {
    if (!tags?.length) return null;

    return (
      <Stack
        direction="row"
        spacing={0.5}
        sx={{
          mt: 0.5, 
          flexWrap: 'wrap',
          gap: 0.5
        }}
      >
        {tags.map((tag) => (
          <TagChip
            key={tag}
            label={tag}
            size="small"
          />
        ))}
      </Stack>
    );
  };

  const renderDeleteDialog = () => (
    <Dialog
      open={deleteDialogOpen}
      onClose={() => setDeleteDialogOpen(false)}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Delete Test Scenario? Archive instead!</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: 2 }}>
          We recommend archiving Test Scenarios instead of deleting.
        </DialogContentText>
        <DialogContentText sx={{ mb: 2 }}>
          Archiving disables the Test Scenario from all Test Suites & Builds,
          but you can always access it in 'Archived' Test Scenarios for later
          reference.
        </DialogContentText>
        <DialogContentText>
          Are you sure you want to permanently delete this Test Scenario?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          startIcon={<InventoryIcon />}
          onClick={onArchiveConfirm}
          variant="outlined"
        >
          Archive
        </Button>
        <Button
          color="error"
          onClick={onDeleteConfirm}
          variant="contained"
        >
          Delete Permanently
        </Button>
      </DialogActions>
    </Dialog>
  );

  const renderTable = () =>
  {
    if (testScenarios.length === 0)
    {
      return (
        <Box sx={{ textAlign: 'center', py: 6 }}>
          <Typography variant="h6" color="text.secondary">
            No results found.
          </Typography>
        </Box>
      );
    }

    return (
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableHeaderCell padding="checkbox" className="checkbox-cell">
                <Checkbox
                  checked={allChecked}
                  onChange={handleAllCheckboxChange}
                  disabled={testScenarios?.length === 0}
                />
              </StyledTableHeaderCell>
              <StyledTableHeaderCell>Details</StyledTableHeaderCell>
              <StyledTableHeaderCell className="date-cell">Created At</StyledTableHeaderCell>
              <StyledTableHeaderCell>Status</StyledTableHeaderCell>
              <StyledTableHeaderCell>Owner</StyledTableHeaderCell>
              <StyledTableHeaderCell>Last Run Result</StyledTableHeaderCell>
              <StyledTableHeaderCell className="action-cell" align="center">Action</StyledTableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {testScenarios.map(({
              id,
              code,
              title,
              status,
              tags,
              owner,
              test_scenario_statuses,
              user_input_needed,
              created_at
            }) => (
              <TableRow
                key={id}
                hover
                sx={{
                  '&:last-child td, &:last-child th': {
                    borderBottom: (theme) => `1px solid ${theme.palette.divider}`
                  }
                }}
              >
                <StyledTableCell padding="checkbox" className="checkbox-cell">
                  <Checkbox
                    checked={checkedTestScenarios.includes(id)}
                    onChange={(e) => handleCheckboxChange(e, id)}
                  />
                </StyledTableCell>


                <StyledTableCell
                  onClick={(e) => onClick(e, id)}
                  sx={{ cursor: 'pointer' }}
                >
                  <Box>
                    <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                      {user_input_needed && (
                        <Tooltip title="User input needed" arrow placement="top">
                          <WarningIcon
                            color="warning"
                            sx={{
                              fontSize: 20,
                              marginRight: 0.5
                            }}
                          />
                        </Tooltip>
                      )}
                      <Typography
                        sx={{
                          fontWeight: 500,
                          lineHeight: 1.5,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          display: '-webkit-box',
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: 'vertical',
                        }}
                      >
                        <Box
                          component="span"
                          sx={{
                            fontWeight: 600,
                            marginRight: 1,
                            whiteSpace: 'nowrap',
                            display: 'inline-block',
                            minWidth: 'fit-content'
                          }}
                        >
                          {code}:
                        </Box>
                        {title}
                      </Typography>
                    </Box>
                    {renderTags(tags)}
                  </Box>
                </StyledTableCell>

                <StyledTableCell className="date-cell">
                  <Typography
                    variant="body2"
                    sx={{
                      width: '100%',
                      textAlign: 'left'
                    }}
                  >
                    {dayjs(created_at * 1000).format('D MMM, YYYY HH:mm')}
                  </Typography>
                </StyledTableCell>


                <StyledTableCell>
                  <StatusChip
                    label={status}
                    status={status}
                    variant="outlined"
                    size="small"
                  />
                </StyledTableCell>
                <StyledTableCell>
                  {owner && (
                    <Tooltip
                      title={owner.name}
                      arrow
                      placement="top"
                    >
                      <UserAvatar>{getInitials(owner.name)}</UserAvatar>
                    </Tooltip>
                  )}
                </StyledTableCell>
                <StyledTableCell>
                  <Stack spacing={0.5}>
                    {(test_scenario_statuses || []).map(({
                      environment_set: env,
                      status: buildStatus,
                      last_run_at: lastRunAt,
                    }, i) => (
                      <Typography
                        key={env?.id || `build-status-${i}`}
                        variant="body2"
                        component="div"
                        sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}
                      >
                        <Box component="span" sx={{ fontWeight: 500 }}>
                          {env?.title || '-'}
                        </Box>
                        :
                        <StatusChip
                          label={lastRunAt ? dayjs(lastRunAt * 1000).fromNow() : buildStatus || '-'}
                          status={buildStatus}
                          size="small"
                        />
                      </Typography>
                    ))}
                  </Stack>
                </StyledTableCell>
                <StyledTableCell className="action-cell" align="center">
                  <IconButton
                    size="small"
                    onClick={(e) => handleMenuClick(e, id)}
                  >
                    <MoreVertIcon fontSize="small" />
                  </IconButton>
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <Paper elevation={1}>
      {isLoading ? null : (
        renderTable()
      )}

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          sx: {
            '& .MuiMenuItem-root': {
              fontSize: '0.95rem',
              padding: '8px 16px'
            }
          }
        }}
      >
        <MenuItem onClick={() =>
        {
          setBuildTestId(menuId);
          handleMenuClose();
        }}>
          Run
        </MenuItem>
        <MenuItem onClick={() => onViewBuilds(menuId)}>
          All builds
        </MenuItem>
        {(() =>
        {
          const status = testScenarios.find(t => t.id === menuId)?.status;
          return (status === 'draft' || status === 'archived') && (
            <MenuItem onClick={() =>
            {
              onMarkActive(menuId);
              handleMenuClose();
            }}>
              Mark as Active
            </MenuItem>
          );
        })()}
        <MenuItem
          onClick={() => onClickDelete(menuId)}
          sx={{ color: 'error.main' }}
        >
          Delete
        </MenuItem>
      </Menu>

      {renderDeleteDialog()}

      <CustomDialog
        open={!!buildTestId}
        title="Select environment"
        btnText="Run"
        onClose={() => setBuildTestId('')}
        options={environmentSets || []}
        handleSubmit={(env) => onBuild(env)}
      />
    </Paper>
  );
};

export default TestScenariosList;