import React from 'react'
import {Box, Grid} from '@mui/material';
import TestCaseSetRequest from './TestCaseSetRequest';
import TestCaseSetEntityResponse from './TestCaseSetEntityResponse';
import TestRules from './TestRules';

const TestCaseSetBody = (props) => {
  return (
    <Box>
      <Grid container>
        <Grid item md={7} sx={7}>
          <Box>
            <TestCaseSetRequest {...props} />
            <TestRules testCase={props.testCase} isExpanded={props.isExpanded} />
          </Box>
        </Grid>
        <Grid item md={5} xs={5}>
          <Box>
            <TestCaseSetEntityResponse testCase={props.testCase} isExpanded={props.isExpanded} selectedEnvironment={props.selectedEnvironment} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default TestCaseSetBody;
