import React, {useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {Box} from '@mui/material';
import TestCaseSetList from './TestCaseSetList';

import { fetchProjectCollections } from '../../redux-store/currentUserActions';

const TestScenarioBody = ({projectId, testScenarioId}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    getCollections();
  }, []);

  const getCollections = async () => {
    await dispatch(fetchProjectCollections(projectId));
  }

  const getNewTestCase = () => {
    return {
      id: Date.now().toString(),
      isNew: true,
      title: "Test case set title",
      collection: null,
      entity: null,
    }
  }

  return (
    <Box>
      <TestCaseSetList testScenarioId={testScenarioId} getNewTestCase={getNewTestCase} projectId={projectId} />
    </Box>
  )
}

export default TestScenarioBody
